import { SessionStorageService } from './session.storage.service';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardAdmin implements CanActivate {
  constructor(private authService: AuthService,
    private sessionStorageService:SessionStorageService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>
    | Promise<boolean>
    | boolean {
    const isAuthenticated = this.authService.isAuthenticated();
    const logged = this.sessionStorageService.getLoggedUser()
    if(logged.role_id!==3){
        return true && isAuthenticated
    }
    return false
  }
}
