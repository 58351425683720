import { UtilsService } from './../../../core/services/utils.service';
import { ApiGeneralService } from './../../../core/services/api/api-general.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { SessionStorageService } from 'src/app/core/services/session.storage.service';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
@ViewChild('viewConfigModal') viewConfigModal:NgbModal
  constructor(
    private apiGeneralService:ApiGeneralService,
    private modalService: NgbModal,
    private sessionStorageService:SessionStorageService,
    private utils:UtilsService
  ) {  }
  
  showConfig = false
  percent_completed:0
  refreshTokenScript:any
  ngOnInit(): void {

    const check_con =  this.sessionStorageService.get('show_config_bar')
    if(check_con!== undefined){
      if(check_con===true){
        //
      }else{
        this.checkConfig()  
      }
    }else{
      this.checkConfig()
    }

    this.refreshTokenScript = setInterval(()=>{
      this.refreshToken()
    },30000)

  }

  refreshToken(){
    this.utils.refreshToken()
  }
  checkConfig(){
    this.apiGeneralService.checkConfig().subscribe((res)=>{
      if(res.status === "OK"){
        this.showConfig = res.data.show_config
        this.percent_completed = res.data.percent_completed
      }
    })
  }
  
  openConfigModal(){
    this.modalService.open(this.viewConfigModal,{windowClass: 'view-config-modal'})
  }

  closeConfig(){
    this.showConfig=false
    this.sessionStorageService.set('show_config_bar',true)
  }


  closeModal(){
    this.modalService.dismissAll()
    this.checkConfig()
  }



}
