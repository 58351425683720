import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject ,  Subject,Observable,of } from 'rxjs';
import {environment} from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable()
export class ApiOrganizationService {

  constructor(private httpClient: HttpClient,private utils:UtilsService) {

  }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  getDnc(filter={}):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/dnclist/filter`,filter, this.utils.getAuthHeader())
  }
  countDnc():Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/dnclist/count`, this.utils.getAuthHeader())
  }
  deleteDnc(ids): Observable<any>{
    return this.httpClient.delete<any>(`${environment.api_url}/api/dnclist/${ids}`, this.utils.getAuthHeader())
  }
  addDnc(key,delimiter): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/dnclist/import`,{key,delimiter}, this.utils.getAuthHeader())
  }

  addDncInput(phones): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/dnclist`,{phones}, this.utils.getAuthHeader())
  }
  getDncLog(phone): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/dnclist/log`,{phone}, this.utils.getAuthHeader())
  }
  deleteOrganization():Observable<any>{
    return this.httpClient.delete<any>(`${environment.api_url}/api/organization`,this.utils.getAuthHeader())
  }
}



