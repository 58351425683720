export class UserModel {
  id:number;
  first_name: string;
  last_name: string;
  email:string;
  password:string;
  language:string;
  time_zone:string;
  cellphone:string;
  picture_url:string;
  enable_2nd_verification:number;
  enable_app_verification:number;
  enable_cellphone_verification:number;
}
