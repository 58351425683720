import {Pipe} from "@angular/core";
import parsePhoneNumber from 'libphonenumber-js'

/**
 * A simple string filter, since ng2 does not yet have a filter pipe built in.
 */
@Pipe({
  name: 'phoneFormat'
})
export class PhoneFormatPipe {
  transform(q: string,nationalFormat: boolean=false) {
    if (!q || q === '') {
      return q;
    }
    const phoneNumber = parsePhoneNumber('+'+q)
    if(phoneNumber){
      if(nationalFormat === true) {
        return phoneNumber.formatNational()
      }else{
        return phoneNumber.formatInternational()
      }
    }
  }
}
