<div class="config-container">
  <div class="config-sidebar">
    <div class="config-logo">
      <img src="assets/img/logo.png" />
    </div>
    <div class="config-header">
      <div class="config-progress">
        <circle-progress class=""
        [percent]="percent_completed"
        [radius]="20"
        [showImage]="false"
        [showTitle]="true"
        [showUnits]="true"
        [outerStrokeWidth]="5"
        [outerStrokeColor]="'#78C000'"
        [showInnerStroke]="false"
        [showSubtitle]="false"
        [animation]="true"
        [animationDuration]="300"
      ></circle-progress>
      </div>

    <div class="config-title">
      {{"Information"|translate}}
      <span>{{"Please fill all the information to complete your profile"}}</span>
    </div>

    </div>

    <div class="config-tab">

      <div class="config-group css-transforms">
        <!-- <aside> 
          <h3 style="bottom: -30px">{{"Personal info"}}</h3>
          <p>
              <a class="btn "  [ngClass]="{'current':current_step==='user-info'}" (click)="showConfigContent('user-info')"> {{"Basic information"|translate}} <i class="fa fa-check-circle"></i> </a>
              <a class="btn "   [ngClass]="{'current':current_step==='user-address'}" (click)="showConfigContent('user-address')"> {{"Address and location"|translate}} <i class="fa fa-check-circle"></i> </a>
              <a class="btn "   [ngClass]="{'current':current_step==='user-contact'}" (click)="showConfigContent('user-contact')"> {{"Contact information"|translate}} <i class="fa fa-check-circle"></i> </a>
          </p>
        </aside> -->
        <aside> 
          <h3 style="bottom: 45px">{{"Organization"|translate}}</h3>
          <p>
              <a class="btn "  [ngClass]="{'completed':completed_steps.includes('organization-info'),'current':current_step==='organization-info'}" (click)="showConfigContent('organization-info')"> {{"Basic information"|translate}} <i *ngIf="completed_steps.includes('organization-info')" class="fa fa-check-circle"></i> <i  *ngIf="!completed_steps.includes('organization-info')" class="fa fa-circle"></i> </a>
              <a class="btn "   [ngClass]="{'completed':completed_steps.includes('organization-address'),'current':current_step==='organization-address'}" (click)="showConfigContent('organization-address')"> {{"Address and location"|translate}}<i *ngIf="completed_steps.includes('organization-address')" class="fa fa-check-circle"></i> <i  *ngIf="!completed_steps.includes('organization-address')" class="fa fa-circle"></i> </a>
              <a class="btn "  [ngClass]="{'completed':completed_steps.includes('organization-contact'),'current':current_step==='organization-contact'}" (click)="showConfigContent('organization-contact')" > {{"Contact information"|translate}} <i *ngIf="completed_steps.includes('organization-contact')" class="fa fa-check-circle"></i> <i  *ngIf="!completed_steps.includes('organization-contact')" class="fa fa-circle"></i></a>
              <a class="btn "  [ngClass]="{'completed':completed_steps.includes('organization-color'),'current':current_step==='organization-color'}" (click)="showConfigContent('organization-color')" > {{"Color and logo"|translate}} <i *ngIf="completed_steps.includes('organization-color')" class="fa fa-check-circle"></i> <i  *ngIf="!completed_steps.includes('organization-color')" class="fa fa-circle"></i></a>
              <a class="btn "  [ngClass]="{'completed':completed_steps.includes('organization-billing'),'current':current_step==='organization-billing'}" (click)="showConfigContent('organization-billing')" > {{"Billing details"|translate}} <i *ngIf="completed_steps.includes('organization-billing')" class="fa fa-check-circle"></i> <i  *ngIf="!completed_steps.includes('organization-billing')" class="fa fa-circle"></i></a>
          </p>
        </aside>

        <aside> 
          <h3 style="bottom: -5px">{{"Payments"|translate}}</h3>
          <p>
              <a class="btn "  [ngClass]="{'completed':completed_steps.includes('payment-method'),'current':current_step==='payment-method'}" (click)="showConfigContent('payment-method')"> {{"Payment Methods"|translate}} <i *ngIf="completed_steps.includes('payment-method')" class="fa fa-check-circle"></i> <i  *ngIf="!completed_steps.includes('payment-method')" class="fa fa-circle"></i> </a>
              <a class="btn "  [ngClass]="{'completed':completed_steps.includes('payment-credit'),'current':current_step==='payment-credit'}" (click)="showConfigContent('payment-credit')"> {{"Account Credit and limits"|translate}} <i *ngIf="completed_steps.includes('payment-credit')" class="fa fa-check-circle"></i> <i  *ngIf="!completed_steps.includes('payment-credit')" class="fa fa-circle"></i> </a>
              <a class="btn "  [ngClass]="{'completed':completed_steps.includes('payment-recurring'),'current':current_step==='payment-recurring'}" (click)="showConfigContent('payment-recurring')"> {{"Recurring Payments"|translate}} <i *ngIf="completed_steps.includes('payment-recurring')" class="fa fa-check-circle"></i> <i  *ngIf="!completed_steps.includes('payment-recurring')" class="fa fa-circle"></i> </a>
          </p>
        </aside>

        <aside> 
          <h3 style="bottom: -50px">{{"Phone and audio"|translate}}</h3>
          <p>
              <a class="btn "   [ngClass]="{'completed':completed_steps.includes('phone-number'),'current':current_step==='phone-number'}" (click)="showConfigContent('phone-number')"> {{"Phone numbers"|translate}} <i *ngIf="completed_steps.includes('phone-number')" class="fa fa-check-circle"></i> <i  *ngIf="!completed_steps.includes('phone-number')" class="fa fa-circle"></i> </a>
              <a class="btn "   [ngClass]="{'completed':completed_steps.includes('phone-audio'),'current':current_step==='phone-audio'}" (click)="showConfigContent('phone-audio')"> {{"Audio Files"|translate}} <i *ngIf="completed_steps.includes('phone-audio')" class="fa fa-check-circle"></i> <i  *ngIf="!completed_steps.includes('phone-audio')" class="fa fa-circle"></i> </a>
              <a class="btn "   [ngClass]="{'completed':completed_steps.includes('phone-status'),'current':current_step==='phone-status'}" (click)="showConfigContent('phone-status')"> {{"Phone statuses"|translate}} <i *ngIf="completed_steps.includes('phone-status')" class="fa fa-check-circle"></i> <i  *ngIf="!completed_steps.includes('phone-status')" class="fa fa-circle"></i> </a>
          </p>
        </aside>

        <aside> 
          <h3 style="bottom: -5px">{{"Team"|translate}}</h3>
          <p>
            <a class="btn "   [ngClass]="{'completed':completed_steps.includes('team-list'),'current':current_step==='team-list'}" (click)="showConfigContent('team-list')"> {{"Teams"|translate}} <i *ngIf="completed_steps.includes('team-list')" class="fa fa-check-circle"></i> <i  *ngIf="!completed_steps.includes('team-list')" class="fa fa-circle"></i> </a>
              <a class="btn "   [ngClass]="{'completed':completed_steps.includes('team-agent'),'current':current_step==='team-agent'}" (click)="showConfigContent('team-agent')"> {{"Agents"|translate}} <i *ngIf="completed_steps.includes('team-agent')" class="fa fa-check-circle"></i> <i  *ngIf="!completed_steps.includes('team-agent')" class="fa fa-circle"></i> </a>
          </p>
        </aside>
    </div>
    </div>
  </div>
  <div class="config-content"> 
    <!-- <div class="config-form" *ngIf="current_step==='user-info'">
      <div class="config-form-header">
        <h3 class="large-title">{{"Account Created Successfully"|translate}}</h3>
        <h4 class="title">{{"Enter additional Details"|translate}}</h4>
        <p class="desc">{{"Review entered information and add additional details"|translate}}</p>
      </div>

      <div class="config-form-body">
        <div class="form m-t-40">
          <div class="form-group">
            <label>{{"First name"|translate}}</label>
            <input class="form-control"  [(ngModel)]="user_basic.first_name" name="first_name" />
          </div>
          <div class="form-group">
            <label>{{"Last name"|translate}}</label>
            <input class="form-control"  [(ngModel)]="user_basic.last_name" name="last_name" />
          </div>
          <div class="form-group">
            <label>{{"Gender"|translate}}</label>
            <select  name="gender" [(ngModel)]="user_basic.gender"  class="form-control" >
              <option value="">{{"Selectionner la gender"|translate}}</option>
              <option *ngFor="let lang of genders" value="{{lang.id}}">{{lang.text}}</option>
            </select>
          </div>

          <div class="form-group">
            <label>{{"Date of birth"|translate}}</label>
            <input    class="form-control" placeholder="dd-mm-yyy" 
                  name="date_of_birth" [(ngModel)]="user_basic.date_of_birth" (dateSelect)="onDateSelection($event)" (click)="d.toggle()"  ngbDatepicker #d="ngbDatepicker">
            </div>

          <div class="form-group">
            <label>{{"Language"|translate}}</label>
            <ng-select2     name="language"    [(ngModel)]="user_basic.language"   select class="form-control" [data]="languages"></ng-select2>
          </div>
          
          <div class="form-group">
            <label>{{"Nationality"|translate}}</label>
            <ng-select2   name="country"  [(ngModel)]="user_basic.country"   select  class="form-control" [data]="nationalities"></ng-select2>
          </div>

        </div>
      </div>

      <div class="config-form-footer">
        <a (click)="skipConfig()" class="btn btn-default btn-previous">{{"Skip"|translate}} <i class="fa fa-angle-double-right"></i> </a>
        <a (click)="continueConfig()" class="btn btn-primary btn-next">{{"Continue"|translate}} </a>
      </div>

    </div>

    <div class="config-form" *ngIf="current_step==='user-address'">
      <h3 class="large-title">{{"Account Created Successfully"|translate}}</h3>
      <h4 class="title">{{"Enter additional Details"|translate}}</h4>
      <p class="desc">{{"Review entered information and add additional details"|translate}}</p>

      <div class="form">
        <div class="form-group">
          <label>{{"Address"|translate}}</label>
          
        </div>
      </div>
    </div>

    <div class="config-form" *ngIf="current_step==='user-contact'">
      <h3 class="large-title">{{"Account Created Successfully"|translate}}</h3>
      <h4 class="title">{{"Enter additional Details"|translate}}</h4>
      <p class="desc">{{"Review entered information and add additional details"|translate}}</p>

      <div class="form">
        <div class="form-group">
          <label>{{"Full name"|translate}}</label>
          
        </div>
      </div>
    </div> -->
    
    <div class="config-form" *ngIf="current_step==='organization-info'">
      <div class="config-form-header">
        <h4 class="title">{{"Organization"|translate}}</h4>
        <p class="desc">{{"Basic Information"|translate}}</p>
      </div>

      <div class="config-form-body">
        <div class="form m-t-40">
          <div class="form-group">
            <label>{{"Organization name"|translate}}</label>
            <input class="form-control"  [(ngModel)]="organization.name" name="first_name" />
          </div>
          <div class="form-group">
            <label>{{"Organization type"|translate}}</label>
            <select class="form-control"  name="type"  [(ngModel)]="organization.type" required >
              <option value="political">{{"Political party"|translate}}</option>
              <option value="union">{{"Union"|translate}}</option>
              <option value="non-profit">{{"Non-profit"|translate}}</option>
              <option value="company">{{"Company"|translate}}</option>
            </select>
          </div>
          
          <div class="form-group">
            <label>{{"Website"|translate}}</label>
            <input class="form-control"  [(ngModel)]="organization.website" name="website" />
          </div>

          <div class="form-group">
            <label>{{"Language"|translate}}</label>
            <select name="default_language" [(ngModel)]="organization.default_language" class="form-control" >
              <option value="">{{"Selectionner la langue désirée"|translate}}</option>
              <option *ngFor="let lang of languages" value="{{lang.id}}">{{lang.name}}</option>
            </select>
          </div>

          <div class="form-group">
            <label>{{"Phone number"|translate}}</label>
            <input class="form-control"  [(ngModel)]="organization.phone" name="phone" />
          </div>

        </div>
      </div>

      <div class="config-form-footer">
        <a (click)="skipConfig()" class="btn btn-default btn-previous">{{"Skip"|translate}} <i class="fa fa-angle-double-right"></i> </a>
        <a (click)="continueConfig()" class="btn btn-primary btn-next">{{"Continue"|translate}} </a>
      </div>

    </div>


    <div class="config-form" *ngIf="current_step==='organization-address'">
      <div class="config-form-header">
        <h4 class="title">{{"Organization"|translate}}</h4>
        <p class="desc">{{"Address and location"|translate}}</p>
      </div>

      <div class="config-form-body">
        <div class="form m-t-40">

          <div class="form-group">
            <label translate>Address</label>
            <input ngx-google-places-autocomplete class="form-control" [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"/>
          </div>

          <div class="form-group">
            <label translate>Pays</label>
            <input type="text" name="billing_country"  [(ngModel)]="organization.billing_country" class="form-control"  >
          </div>
          <div class="form-group">
            <label translate>Ville</label>
            <input type="text" name="billing_city"  [(ngModel)]="organization.billing_city" class="form-control"  >
          </div>


          <div class="form-group">
            <label translate>Province</label>
            <input type="text" name="billing_province"  [(ngModel)]="organization.billing_province"  class="form-control"  >
          </div>
          <div class="form-group">
            <label translate>Code postal</label>
            <input type="text" name="billing_codepostal"  [(ngModel)]="organization.billing_codepostal"  class="form-control"  >
          </div>


          <div class="form-group">
            <label translate>Fuseau horaire local</label>
            <ng-select2 name="time_zone" [(ngModel)]="organization.time_zone" class="form-control" [data]="timezones"></ng-select2>
          </div>
        </div>
      </div>

      <div class="config-form-footer">
        <a (click)="skipConfig()" class="btn btn-default btn-previous">{{"Skip"|translate}} <i class="fa fa-angle-double-right"></i> </a>
        <a (click)="continueConfig()" class="btn btn-primary btn-next">{{"Continue"|translate}} </a>
      </div>

    </div>

    <div class="config-form" *ngIf="current_step==='organization-contact'">
      <div class="config-form-header">
        <h4 class="title">{{"Organization"|translate}}</h4>
        <p class="desc">{{"Contact information"|translate}}</p>
      </div>

      <div class="config-form-body">
        <div class="form m-t-40">
          <div class="form-group">
            <label translate>Email</label>
            <input type="text" name="billing_email"  [(ngModel)]="organization.billing_email" class="form-control"  >
          </div>

          <div class="form-group">
            <label translate>Phone</label>
            <input type="text" name="billing_phone"  [(ngModel)]="organization.billing_phone" class="form-control"  >
          </div>


          <div class="form-group">
            <label translate>Website</label>
            <input type="text" name="website"  [(ngModel)]="organization.website"  class="form-control"  >
          </div>
          
        </div>
      </div>

      <div class="config-form-footer">
        <a (click)="skipConfig()" class="btn btn-default btn-previous">{{"Skip"|translate}} <i class="fa fa-angle-double-right"></i> </a>
        <a (click)="continueConfig()" class="btn btn-primary btn-next">{{"Continue"|translate}} </a>
      </div>

    </div>


    <div class="config-form" *ngIf="current_step==='organization-color'">
      <div class="config-form-header">
        <h4 class="title">{{"Organization"|translate}}</h4>
        <p class="desc">{{"Colors and Logo"|translate}}</p>
      </div>

      <div class="config-form-body">
        <div class="form m-t-40">
          <div class="form-group">
            <label translate>Upload Organization logo</label>
            
            <div class="upload-logo">
              <a (click)="showUploadLogo()"><i class="fa fa-upload"></i> {{"Click to browse"|translate}}</a>
            </div>

            <input class="form-control" type="file" style="display: none" id="file" (change)="fileChangeEvent($event)" />
            <div class="text-center">
              <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [aspectRatio]="4 / 4"
                [resizeToWidth]="256"
                format="png"
                (imageCropped)="imageCropped($event)">
              </image-cropper>
            </div>
            <img src="{{logo_url}}" />

          </div>

          <div class="form-group">
            <label translate>Organization Colors</label>
            
            <div class="organization-color">
              <ul>
                <li [style.backgroundColor]="organization.color_primary">
                  <input [(colorPicker)]="organization.color_primary"  [cpOutputFormat]="'hex'"  [(ngModel)]="organization.color_primary" class="color-input form-control" />
                  <span>{{"Primary"|translate}}</span>
                </li>
                <li [style.backgroundColor]="organization.color_secondary">
                  <input [(colorPicker)]="organization.color_secondary"  [cpOutputFormat]="'hex'"   [(ngModel)]="organization.color_secondary" class="color-input form-control" />
                  <span>{{"Secondary"|translate}}</span>
                </li>
                <li [style.backgroundColor]="organization.color_tertiary">
                  <input [(colorPicker)]="organization.color_tertiary"   [cpOutputFormat]="'hex'"  [(ngModel)]="organization.color_tertiary" class="color-input form-control" />
                  <span>{{"Tertiary"|translate}}</span>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>

      <div class="config-form-footer">
        <a (click)="skipConfig()" class="btn btn-default btn-previous">{{"Skip"|translate}} <i class="fa fa-angle-double-right"></i> </a>
        <a (click)="continueConfig()" class="btn btn-primary btn-next">{{"Continue"|translate}} </a>
      </div>

    </div>


    <div class="config-form" *ngIf="current_step==='organization-billing'">
      <div class="config-form-header">
        <h4 class="title">{{"Organization"|translate}}</h4>
        <p class="desc">{{"Billing details"|translate}}</p>
      </div>

      <div class="config-form-body">
        <div class="form m-t-40">

          <div class="form-group">
            <label translate>Billing first name</label>
            <input type="text" name="billing_firstname"  [(ngModel)]="organization.billing_firstname" class="form-control"  >
          </div>

          <div class="form-group">
            <label translate>Billing name</label>
            <input type="text" name="billing_name"  [(ngModel)]="organization.billing_name" class="form-control"  >
          </div>

          <div class="form-group">
            <label translate>Billing address</label>
            <input type="text" name="billing_address" [(ngModel)]="organization.billing_address" class="form-control"  >
          </div>

          <div class="form-group">
            <label translate>Billing email</label>
            <input type="text" name="billing_email"  [(ngModel)]="organization.billing_email" class="form-control"  >
          </div>

          <div class="form-group">
            <label translate>Notes</label>
            <input type="text" name="billing_notes"  [(ngModel)]="organization.billing_notes"  class="form-control"  >
          </div>
          
        </div>
      </div>

      <div class="config-form-footer">
        <a (click)="skipConfig()" class="btn btn-default btn-previous">{{"Skip"|translate}} <i class="fa fa-angle-double-right"></i> </a>
        <a (click)="continueConfig()" class="btn btn-primary btn-next">{{"Continue"|translate}} </a>
      </div>

    </div>


    <div class="config-form" *ngIf="current_step==='payment-method'">
      <div class="config-form-header">
        <h4 class="title">{{"Payments"|translate}}</h4>
        <p class="desc">{{"Add payment methods"|translate}}</p>
      </div>

      <div class="config-form-body">

        <div class="card-container">

          <div class="card" *ngFor="let credit of credits;let i=index"  [ngClass]="{'default':credit.is_use_default==1}" >
            <div class="card-title">
              {{"card"|translate}} {{i+1}}
              <a (click)="makeDefaultCredit(credit)"> <i class="fa fa-circle"></i></a>
            </div>
            <div class="card-type">
              <i class="fab fa-cc-{{credit.brand.toLowerCase()}}"></i>
            </div>
            <div class="card-number">
              XXXX XXXX XXXX {{credit.last4}}
            </div>
            <div class="card-delete">
              <a class="btn " (click)="deleteCredit(credit.id)" ><i class="fa fa-trash-alt"></i></a>
            </div>
          </div>
        </div>

        <div class="form m-t-40" >
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label>{{"Card number"|translate}}</label>
                <div class="form-control" id="card_number"></div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>{{"Expiry Date"|translate}}</label>
                <div class="form-control" id="card_expiry"></div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>{{"CVV"|translate}}</label>
                <div class="form-control" id="card_cvc"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="config-form-footer">
        <a (click)="skipConfig()" class="btn btn-default btn-previous">{{"Skip"|translate}} <i class="fa fa-angle-double-right"></i> </a>
        <a (click)="continueConfig()" class="btn btn-primary btn-next">{{"Continue"|translate}} </a>
      </div>

    </div>


    <div class="config-form" *ngIf="current_step==='payment-credit'">
      <div class="config-form-header">
        <h4 class="title">{{"Payments"|translate}}</h4>
        <p class="desc">{{"Add Account Credits and Set Limits"|translate}}</p>
      </div>

      <div class="config-form-body">

        <p class="account-credit">{{"Your account credit"|translate}} : {{organization.credit}}</p>

        <div class="card-container">

          <div class="card" [ngClass]="{'active':payment.customer_id==credit.customer_id}" *ngFor="let credit of credits;let i=index">
            <div class="card-title">
              {{"card"|translate}} {{i+1}}
              <a (click)="payment.customer_id=credit.customer_id" ><i class="fa fa-circle"></i></a>
            </div>
            <div class="card-type">
              <i class="fab fa-cc-{{credit.brand.toLowerCase()}}"></i>
            </div>
            <div class="card-number">
              XXXX XXXX XXXX {{credit.last4}}
            </div>
          </div>
        </div>
        <div class="credit-form m-t-30">
          <form>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label>{{"Amount"|translate}}</label>
                  <input type="number" name="amount" [(ngModel)]="payment.amount"  class="form-control" />
                </div>

                <!-- <div class="form-group">
                  <label>{{"Set limit for credit"|translate}}</label>
                  <input type="number" name="limit" [(ngModel)]="payment.limit"  class="form-control" />
                </div> -->
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="config-form-footer">
        <a (click)="skipConfig()" class="btn btn-default btn-previous">{{"Skip"|translate}} <i class="fa fa-angle-double-right"></i> </a>
        <a (click)="continueConfig()" class="btn btn-primary btn-next">{{"Continue"|translate}} </a>
      </div>

    </div>

    <div class="config-form" *ngIf="current_step==='payment-recurring'">
      <div class="config-form-header">
        <h4 class="title">{{"Payments"|translate}}</h4>
        <p class="desc">{{"Set Recurring payment options"|translate}}</p>
      </div>

      <div class="config-form-body">


        <p class="account-credit">{{"Your account recurring payment"|translate}} : {{recurrent_donate.amount}}</p>
                
        <div class="card-container">

          <div class="card" [ngClass]="{'active':recurrent_donate.customer_id==credit.customer_id}" *ngFor="let credit of credits;let i=index">
            <div class="card-title">
              {{"card"|translate}} {{i+1}}
              <a (click)="recurrent_donate.customer_id=credit.customer_id" ><i class="fa fa-circle"></i></a>
            </div>
            <div class="card-type">
              <i class="fab fa-cc-{{credit.brand.toLowerCase()}}"></i>
            </div>
            <div class="card-number">
              XXXX XXXX XXXX {{credit.last4}}
            </div>
          </div>
        </div>
        <div class="credit-form m-t-30">
          <form>
            <div class="row">

              <div class="col-sm-12">

                <div class="form-group">
                  <label>{{"Payment Frequency"}}</label>
                    
                  <select class="form-control" name="role_id"  [(ngModel)]="recurrent_donate.recurrency" >
                    <option value="weekly">{{"Each week"|translate}}</option>
                    <option value="monthly">{{"Each month"|translate}}</option>
                  </select>

                </div>
                <div class="form-group">
                  <label>{{"Amount"}}</label>
                  <input type="number" name="amount" [(ngModel)]="recurrent_donate.amount"  class="form-control" />
                </div>

                <div class="form-group">
                  <label>{{"Credit Threshold"|translate}}</label>
                  <input type="number" name="amount_exceed" [(ngModel)]="recurrent_donate.amount_exceed"  class="form-control" />
                </div>


                <div class="form-group">
                  <label>{{"Date (if applicable)"|translate}}</label>
                  <input class="form-control" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" [(ngModel)]="recurrent_donate.start_date" name="start_date" /> 
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="config-form-footer">
        <a (click)="skipConfig()" class="btn btn-default btn-previous">{{"Skip"|translate}} <i class="fa fa-angle-double-right"></i> </a>
        <a (click)="continueConfig()" class="btn btn-primary btn-next">{{"Continue"|translate}} </a>
      </div>

    </div>



    <div class="config-form" *ngIf="current_step==='phone-number'">
      <div class="config-form-header">
        <h4 class="title">{{"Phone Numbers"|translate}}</h4>
        <p class="desc" *ngIf="phone_wizard_step === 0">{{"Add your own number or rent one"|translate}}</p>
        <p class="desc" *ngIf="phone_wizard_step === 1">{{"Confirm phone number"|translate}}</p>
        <p class="desc" *ngIf="phone_wizard_step === 2">{{"Active the phone"|translate}}</p>
      </div>

      <div class="config-form-body">
        <p class="account-credit">{{"Your active phone number"|translate}} : {{phone_number.phone}}</p>

        <div *ngIf="phone_type==='own'">
          <div class="form m-t-40" *ngIf="phone_wizard_step===0">
            
            <div class="form-group">
              <label>{{"Country"|translate}}</label>
              <ng-select [(ngModel)]="verifyModel.code"  (change)="onChangePhoneCountry()"  name="code"  class="form-control"  [items]="countries_phone" 
                bindLabel="text" 
                bindValue="id" 
                >
              </ng-select>
            </div>

            <div class="form-group">
              <label>{{"Phone number"|translate}}</label>
              <input type="text" class="form-control" mask="{{phone_mask}}"  [specialCharacters]="specialCharacters" [prefix]="phone_prefix" name="phone" [(ngModel)]="verifyModel.phone">
            </div>
            
            <p class="or-divider"> {{"Or"|translate}} </p>

            <p class="text-center"><a class="btn btn-rent-phone btn-default " (click)="rentPhoneNumber()" > {{"Rent a pre-configured number"|translate}} </a></p>

          </div>

          <div class="form m-t-40" *ngIf="phone_wizard_step===1">
            
            <div class="row m-t-40 m-b-20">
              <div class="col-sm-12">
                <div class="form-group small-input">
                  <label>{{"Enter the code"|translate}}</label>
                  <input type="text" class="form-control" [(ngModel)]="verifyModel.verify_code">
                </div>
              </div>
            </div>
            <p class="wizzard-text">{{"Didn’t get?"|translate}} <a (click)="resendCode()">{{"Resend"|translate}}.</a></p>
          </div>

          <div class="form m-t-40" *ngIf="phone_wizard_step===2">
            
            <p>{{"It worked"|translate}} !</p>
            <p>{{"Need your confirmation to activate"|translate}}</p>
            <div class="row m-t-40 m-b-20">
              <div class="col-sm-12">
                <ul class="phone_confirmation  small-input">
                  <li>{{"Outbound call"}} <i class="far fa-check-circle"></i></li>
                  <li>{{"Inbound call"}} <i class="far fa-check-circle"></i></li>
                  <li>{{"Multimedia Messaging Service (MMS)"}} <i class="fas fa-times text-danger"></i></li>
                  <li>{{"Short Message System (SMS)"}} <i class="fas fa-times text-danger"></i></li>
                </ul>
              </div>
            </div>
          </div>
        </div>


        <div *ngIf="phone_type==='buy'">

           <div class="m-t-40" *ngIf="rent_phone_wizard_step===0">
            <div class="form-content m-t-10">
              <table class="table m-t-20" width="100%">
                <thead>
                <tr>
                  <th>{{"Number"|translate}}</th>
                  <th>{{"Region"|translate}}</th>
                  <th>{{"City"|translate}}.</th>
                  <th>{{"SMS"|translate}}</th>
                  <th>{{"MMS"|translate}}</th>
                  <th>{{"Call"|translate}}</th>
                  <th>{{"Renting price"|translate}}</th>
                  <th>{{"Country"|translate}}</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of asyncPhoneRows | async | paginate: { id: 'serverPhone', itemsPerPage: phoneLimit, currentPage: phonePage, totalItems: phoneTotal }">
                  <td nowrap>
                    {{item.number|phoneFormat}}
                  </td>
                  <td>{{item.region}}</td>
                  <td>{{item.city}}</td>
                  <td><span *ngIf="item.smsEnabled===true" class="btn btn-active"><i class="fa fa-check"></i></span><span *ngIf="item.smsEnabled===false" class="btn btn-inactive"><i class="fa fa-times  btn-fa" ></i></span></td>
                  <td><span *ngIf="item.mmsEnabled===true" class="btn btn-active"><i class="fa fa-check"></i></span><span *ngIf="item.mmsEnabled===false" class="btn btn-inactive"><i class="fa fa-times  btn-fa" ></i></span></td>
                  <td><span *ngIf="item.voiceEnabled===true" class="btn btn-active"><i class="fa fa-check"></i></span><span *ngIf="item.voiceEnabled===false" class="btn btn-inactive"><i class="fa fa-times  btn-fa" ></i></span></td>
                  <td>10$ / {{"month"|translate}}</td>
                  <td>{{item.country}}</td>
                  <td>
                    <a (click)="rentPhone(item)" class="btn btn-primary btn-rent">{{"Rent"|translate}}</a>
                  </td>
                </tr>
                </tbody>
              </table>
              <app-pagination [paging_id]="'serverPhone'" (changeLimit)="applyLimitPhone($event.toString())" (changePage)="getPagePhone($event)"></app-pagination>
            </div>
          </div>

          <div class="m-t-40" *ngIf="rent_phone_wizard_step === 1">
            
            <form #phoneForm>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group required" [ngClass]="{'error':phoneModel.phone==''}">
                    <label>{{"Phone"|translate}}</label>
                    <input type="text" name="phone" [readOnly]="currentPhoneId!=0" [(ngModel)]="phoneModel.phone" required="" placeholder="{{'Commencer à écrire'|translate}}"   class="form-control" >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group required " [ngClass]="{'error':phoneModel.name==''}">
                    <label>{{"Name"|translate}}</label>
                    <input name="name" [(ngModel)]="phoneModel.name"  required="" class="form-control"/>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>{{"Caller name"|translate}}</label>
                    <input name="caller_name" [(ngModel)]="phoneModel.caller_name" class="form-control"/>
                  </div>
                </div>
              </div>
            </form>

            <div class="row actions m-t-50">
              <div class="col-sm-12">
                <button class="btn btn-primary btn-submit m-r-10" [disabled]="phoneForm.invalid" (click)="savePhoneForm(phoneForm)">
                  {{"Ajouter"|translate}}
                </button>
                <a class="btn btn-default" (click)="closePhoneForm()">
                  {{"annuler"|translate}}
                </a>
              </div>
            </div>
          </div>

        </div>
          

      </div>

      <div class="config-form-footer">
        <a (click)="skipConfig()" class="btn btn-default btn-previous">{{"Skip"|translate}} <i class="fa fa-angle-double-right"></i> </a>
        <a (click)="continueConfig()" *ngIf="phone_wizard_step!==2"class="btn btn-primary btn-next">{{"Continue"|translate}} </a>
        <a (click)="continueConfig()" *ngIf="phone_wizard_step===2" class="btn btn-primary btn-next">{{"Activate"|translate}} </a>
      </div>

    </div>


    <div class="config-form" *ngIf="current_step==='phone-audio'">
      <div class="config-form-header">
        <h4 class="title">{{"Audio File"|translate}}</h4>
        <p class="desc" *ngIf="show_recording===false">{{"Upload or Create audio recording"|translate}}</p>
        <p class="desc" *ngIf="recording_step===0">{{"Create an audio recording"|translate}}</p>
        
      </div>

      <div class="config-form-body">
        <p class="account-credit">{{"Your audio files"|translate}} : {{audio_files.name}}</p>

        <div class="form m-t-40" *ngIf="show_recording===false">
          <div class="form-group">
            <label translate>Upload Audio file</label>
            <div class="upload-logo">
              <a (click)="showUploadAudio()"><i class="fa fa-upload text-primary"></i> {{"Click to browse"|translate}}</a>
            </div>
            <input class="form-control" type="file" style="display: none" accept=".mp3,.wav"  id="file_audio" (change)="fileChange($event)" />

            <!-- <p class="or-divider">{{"Or"|translate}}</p>

            <div class="upload-logo">
              <a (click)="showRecordAudio()"><i class="fa fa-microphone text-primary"></i> {{"Record to create an audio file"|translate}}</a>
            </div> -->
          </div>
        </div>


        <div class="form m-t-40" *ngIf="show_recording===true">
          <div class="record-area" *ngIf="recording_state==='ready'">
            <p><a class="inline full-width text-white" (click)="startRecording()" >{{"Cliquer pour débuter l'enregistrement"|translate}}...</a></p>
            <a class="btn btn-record" (click)="startRecording()">
              <i class="fa fa-microphone text-white "></i>
            </a>
          </div>
          <div class="record-area" *ngIf="recording_state==='recording'">
            <p>{{"Recording"|translate}}...</p>
            <a class="btn btn-record" (click)="stopRecording()">
              <i class="fa fa-stop text-white "></i>
            </a>
            <p class="m-t-30">
              <img src="assets/img/audio-wave-32.gif"><img src="assets/img/audio-wave-32.gif"><img src="assets/img/audio-wave-32.gif"><img src="assets/img/audio-wave-32.gif"><img src="assets/img/audio-wave-32.gif">
            </p>
          </div>
          <div class="record-area" *ngIf="recording_state==='stop'">
            <p>{{"Give your recoding a name"|translate}}...</p>
            <div class="form-group">
              <label>{{"File name"|translate}}</label>
              <input class="form-control" type="text" #record_file_name>
            </div>
            <p>
              <a (click)="playRecordAudio()" class="btn btn-play m-r-10"><i class="fa fa-play"></i></a> <img src="assets/img/audio-wave-32.gif"><img src="assets/img/audio-wave-32.gif"><img src="assets/img/audio-wave-32.gif"><img src="assets/img/audio-wave-32.gif"><img src="assets/img/audio-wave-32.gif">
            </p>
            <div class="action p-b-20 p-t-20">
              <div class="float-left">
                <a class="btn btn-icon" (click)="recordAgain()">
                  <i class="fa fa-redo-alt"></i>
                </a><br/>
                {{"Record again"|translate}}
              </div>
              <a class="btn btn-submit float-right" (click)="saveRecord(record_file_name)"  translate>Save</a>
            </div>
          </div>

        </div>

      </div>

      <div class="config-form-footer">
        <a (click)="skipConfig()" class="btn btn-default btn-previous">{{"Skip"|translate}} <i class="fa fa-angle-double-right"></i> </a>
        <button (click)="continueConfig()" *ngIf="show_recording === true" [disabled]="can_continue_recording===false"  class="btn btn-primary btn-next">{{"Continue"|translate}} </button>
      </div>

    </div>

    

    <div class="config-form" *ngIf="current_step==='phone-status'">
      <div class="config-form-header">
        <h4 class="title">{{"Phone Statuses"|translate}}</h4>
        <p class="desc">{{"Setup a few status for use by the agent"|translate}}</p>
      </div>

      <div class="config-form-body">
        <div class="form m-t-40">
          
          <div class="phone_status_list">
            <ul>
              <li *ngFor="let item of phone_status" (click)="editPhoneStatus(item)" [style.backgroundColor]="item.color">
                {{item.code}}
              </li>
              <li class="add-phone-status">
                <a (click)="showPhoneStatusForm()"><i class="fa fa-plus"></i></a>
              </li>
            </ul>
          </div>

          <div *ngIf="showStatusForm===true" class="m-t-20">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>{{"Group"|translate}}</label>
                  <input type="text" name="group_name" [(ngModel)]="callStatusModel.group_name"  placeholder="{{'Commencer à écrire'|translate}}" ngModel  class="form-control" >
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>{{"Name"|translate}}</label>
                  <input type="text" name="name" [(ngModel)]="callStatusModel.name"  placeholder="{{'Commencer à écrire'|translate}}" ngModel  class="form-control" >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>{{"Code"|translate}}</label>
                  <input type="text" name="code" [(ngModel)]="callStatusModel.code"  [disabled]="fixedCode.includes(callStatusModel.code)" class="form-control" placeholder="{{'Commencer à écrire'|translate}}" />
                </div>
              </div>
              <div class="col-sm-6">
                <label>{{"Color"|translate}}</label>
                <input [(colorPicker)]="callStatusModel.color"  name="color" [(ngModel)]="callStatusModel.color" [style.background]="callStatusModel.color"  class="form-control"/>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label>{{"Description"|translate}}</label>
                  <textarea rows="5" class="form-control" name="description" [(ngModel)]="callStatusModel.description" ></textarea>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <a (click)="savePhoneStatus()" class="btn btn-primary float-right btn-next">{{"Save"|translate}} </a>
              </div>
            </div>
          </div>


          
          
        </div>
      </div>

      <div class="config-form-footer">
        <a (click)="skipConfig()" class="btn btn-default btn-previous">{{"Skip"|translate}} <i class="fa fa-angle-double-right"></i> </a>
        <a (click)="continueConfig()" class="btn btn-primary btn-next">{{"Continue"|translate}} </a>
      </div>

    </div>


    <div class="config-form" *ngIf="current_step==='team-agent'">
      <div class="config-form-header">
        <h4 class="title">{{"Invite a Team Member"|translate}}</h4>
        <p class="desc">{{"Invite people and assign roles"|translate}}</p>
      </div>

      <div class="config-form-body">
        <div class="form m-t-40">
          <div class="row m-b-10">
            <div class="col-sm-12">
              <div class="form-group required" [ngClass]="{'error':email_error===true,'success':email_error===false}">
                <label>{{"Email"|translate}}</label>
                <input type="email" required name="email" id="email"  [(ngModel)]="userModel.email" class="form-control" email>
              </div>
            </div>
          </div>
          <div class="row m-b-10">
            <div class="col-sm-6">
              <div class="form-group required">
                <label>{{"First name"|translate}}</label>
                <input type="text" name="first_name"  required=""  class="form-control"  [(ngModel)]="userModel.first_name" >
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group required">
                <label>{{"Last name"|translate}}</label>
                <input type="text" name="last_name"   required="" class="form-control"  [(ngModel)]="userModel.last_name"  >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group required" [ngClass]="{'error':role_error===true,'success':role_error===false}">
                <label>{{"Role"|translate}}</label>
                <select class="form-control" name="role_id"  [(ngModel)]="userModel.role_id" >
                  <option value="2">{{"Administrator"|translate}}</option>
                  <option value="3">{{"Agent"|translate}}</option>
                </select>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>{{"Team"|translate}}</label>
                <ng-select2  [(ngModel)]="userModel.teams"  name="teams"   class="form-control" [options]="{
                  multiple: true,
                  theme: 'classic',
                  closeOnSelect: false
                }" [data]="teams"></ng-select2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="config-form-footer">
        <a (click)="skipConfig()" class="btn btn-default btn-previous">{{"Skip"|translate}} <i class="fa fa-angle-double-right"></i> </a>
        <a (click)="continueConfig()" class="btn btn-primary btn-next">{{"Continue"|translate}} </a>
      </div>

    </div>

    <div class="config-form" *ngIf="current_step==='team-list'">
      <div class="config-form-header">
        <h4 class="title">{{"Create a team"|translate}}</h4>
        <p class="desc">{{"Select agents to make your team"|translate}}</p>
      </div>

      <div class="config-form-body">
        <div class="form m-t-40">
          
          <div class="row m-b-10">
            <div class="col-sm-12">
              <div class="form-group required">
                <label>{{"Team name"|translate}}</label>
                <input type="text" name="name"  required=""  class="form-control"  [(ngModel)]="teamModel.name" >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label>{{"Agents"|translate}}</label>
                <ng-select2  [(ngModel)]="teamModel.agents"  name="agents"   class="form-control" [options]="{
                  multiple: true,
                  theme: 'classic',
                  closeOnSelect: false
                }" [data]="agents"></ng-select2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="config-form-footer">
        <a (click)="skipConfig()" class="btn btn-default btn-previous">{{"Skip"|translate}} <i class="fa fa-angle-double-right"></i> </a>
        <a (click)="continueConfig()" class="btn btn-primary btn-next">{{"Continue"|translate}} </a>
      </div>

    </div>
    

  </div>
</div>




