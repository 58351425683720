import { ApiUserService } from 'src/app/core/services/api/api-user.service';
import { Intercom } from 'ng-intercom';
import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AuthService } from './core/services/auth.service';
import {SessionStorageService} from "./core/services/session.storage.service";
import {TranslateService} from "@ngx-translate/core";
import {UtilsService} from "./core/services/utils.service";

import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
// import CobrowseIO from 'cobrowse-sdk-js'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isAuthenticated = false;
  isPartiLogged = false

  constructor(private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private sessionStorageService:SessionStorageService,
              private apiService:ApiUserService,
              private translate: TranslateService,
              private utils: UtilsService,
              private intercom:Intercom
    ) {
    translate.addLangs(['en', 'fr'])
    const  userLang = navigator.language.split("-")[0];
    if(userLang==="en" || userLang==="vi"){
      translate.setDefaultLang("en")
    }else{
      translate.setDefaultLang("fr")
    }
  }
  

  ngOnInit() {
    const colors = new Map([
      ['primary', '#5E81F4'],
      ['primary_opacity','rgba(94, 129, 244, 0.1)'],
      
    ])

    Array.from(colors.entries()).forEach(([name, value]) => {
      document.body.style.setProperty(`--${name}`, value);
    })

    this.intercom.boot({
      app_id:"lwjs0ya2"
    });

    // CobrowseIO.license = 'InigMZzgSZnhgw'
    // CobrowseIO.start()
    this.authService.authStatusChanged.subscribe(
      (authenticated) => {
        this.isAuthenticated = authenticated;
        if (authenticated === true) {

          const cognitoUser = this.authService.getAuthenticatedUser()
          if(cognitoUser){

            cognitoUser.getSession((err,session)=>{

              this.apiService.checkValidToken(session.getAccessToken().getJwtToken()).subscribe(res=>{
                if(res.status==="ERROR"){
                  console.log('session invalid')
                  cognitoUser.signOut()
                  this.router.navigateByUrl('/');
                }else{
                  const logged = this.sessionStorageService.get('logged')
                  if(logged!==undefined){
                    if(logged.user.language){
                      this.translate.use(logged.user.language)
                    }

                    const url = window.location.pathname.split('?')[0];

                    if(logged.is_logged_as_super_admin===1){
                      if(url === '/' || url === '/signin' || url === "/signin/signup"){
                        this.router.navigateByUrl('/super-admin/db-tool')
                      }
                    }else{

                      let organization_name = "";
                      if(logged.organization && logged.organization.name){
                        organization_name = logged.organization.name
                      }

                      this.intercom.boot({
                        app_id:"lwjs0ya2",
                        Organisation:`V3 : ${organization_name}`,
                        name:`${logged.user.first_name} ${logged.user.last_name}`,
                        email:logged.user.email
                      });

                      Sentry.setTag("organization", `Callcenter : ${organization_name}`);
                      Sentry.setTag("User name", `${logged.user.first_name} ${logged.user.last_name}`);
                      Sentry.setTag("Email", logged.user.email);


                      this.isPartiLogged = true
                      if(url === '/' || url === '/signin' || url === "/signin/signup"){
                        if(organization_name){
                          if(logged.role_id === 3){
                            this.router.navigateByUrl('/user/dashboard')
                          }else{
                            this.router.navigateByUrl('/admin/users')
                          }
                        }else{
                          this.router.navigateByUrl('/signin/parties')
                        }
                          
                      }else{
          
                        if(logged && logged.organization && logged.organization.name!==undefined){
                          this.utils.applyOrganizationColor()
                        }else{
                          this.router.navigateByUrl('/signin/parties')
                        }
                      }
                    }
                  }else{
                    this.router.navigateByUrl('/signin/parties')
                  }
                  
                }
              })

            })
          }else{
            try {
              cognitoUser.signOut()
              this.router.navigateByUrl('/');
            } catch (error) {
              this.router.navigateByUrl('/');
            }
          }

        } else {
          let url = window.location.pathname.split('?')[0];
          if(url === '/' || url === "/signin/signup"|| url === "/document"){
            //
          }else{
            this.router.navigateByUrl('/');
          }
        }
      }
    );
    this.authService.initAuth();

    Sentry.init({
      dsn: "https://4e1a17fb9b7d4b8ca9ca15a03307cbca@o1143197.ingest.sentry.io/6202479",
      release: "Predictive caller@1.0",
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });

  }

  onLogout() {
    this.authService.logout();
    this.sessionStorageService.clear()
  }
}
