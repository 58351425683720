import { UtilsService } from './../../../core/services/utils.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  constructor(
    private utils:UtilsService
  ) { }

  refreshTokenScript:any
  ngOnInit(): void {

    this.refreshTokenScript = setInterval(()=>{
      this.refreshToken()
    },30000)

  }

  refreshToken(){
    this.utils.refreshToken()
  }

}
