import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable()
export class ApiQuickBookService {
  api_name = "quick-book"
  constructor(
    private httpClient: HttpClient,
    private utils:UtilsService
  ) {
  }

  getAuthorizationUri():Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/authorization-uri`, this.utils.getAuthHeader())
  }
  getToken(url):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/token`,{url}, this.utils.getAuthHeader())
  }

  checkToken():Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/check-token`, this.utils.getAuthHeader())
  }
}



