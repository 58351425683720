import { Injectable } from '@angular/core';
import {SessionStorageService} from "./session.storage.service";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../environments/environment";
import {CallCenterToast} from "../../shared/call-center.toast";
import {ToastrService} from "ngx-toastr";
import parsePhoneNumberFromString from 'libphonenumber-js'
import validator from 'validator';

import * as CryptoJS from 'crypto-js';

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { constants } from 'perf_hooks';

import * as AWS from 'aws-sdk/global';


import {
	CognitoUserPool,
	CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import { async } from 'rxjs';

const POOL_DATA = {
	UserPoolId:environment.AwsUserPoolId, // Your user pool id here
	ClientId: environment.AwsClientId, // Your client id here
};
const userPool = new CognitoUserPool(POOL_DATA);

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor(
    private sessionStorageService:SessionStorageService,
    private router:Router,
    private translate:TranslateService,
    private toastr: ToastrService,
    private httpClient: HttpClient
  ) {  }
  getRandomString(length) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for ( var i = 0; i < length; i++ ) {
      result += randomChars.charAt(Math.floor(Math. random() * randomChars.length));
    }
    return result;
  }
  adminRefresh(url){
    this.router.navigateByUrl('/admin/refresh', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(url);
    });
  }
  getMenuTitle(){
    return {
      users:{
        title:this.translate.instant("Agents"),
        sub_title:this.translate.instant("Centre d'appel")
      },
      list:{
        title:this.translate.instant("List"),
        sub_title:this.translate.instant("Centre d'appel")
      },
      campaign:{
        title:this.translate.instant("Campaign"),
        sub_title:this.translate.instant("Centre d'appel")
      },
      'audio-files':{
        title:this.translate.instant("Audio files"),
        sub_title:this.translate.instant("Centre d'appel")
      },
      dnc:{
        title:this.translate.instant("DNC"),
        sub_title:this.translate.instant("Centre d'appel")
      }
    }
  }


  async refreshToken(force=0){
    const cognitoUser = userPool.getCurrentUser()
    // console.log(cognitoUser)
    const logged = this.sessionStorageService.getLoggedUser()
    cognitoUser.getSession((err, session) => {
      if(err){
        console.log('session error 1',err)
        cognitoUser.signOut()
        this.router.navigateByUrl('/')
        
      }else{
        const access_token= session.getAccessToken().getJwtToken()
        if(logged.token !== access_token || session.isValid() === false){
          
          const refresh_token = session.getRefreshToken(); // receive session from calling cognitoUser.getSession()
            
          AWS.config.region = environment.AwsRegion;
          const user_pool_key = 'cognito-idp.'+environment.AwsRegion+'.amazonaws.com/'+environment.AwsUserPoolId
          const login = {}
          login[user_pool_key]=session.getIdToken().getJwtToken();

          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: environment.AwsIdentifyPoolId, // your identity pool id here
            Logins: login,
          });

          if((AWS.config.credentials as AWS.CognitoIdentityCredentials).needsRefresh()){

            cognitoUser.refreshSession(refresh_token, (err, session_new) => {
              if (err) {
                // cognitoUser.signOut()
                console.log('session error 2',err)
                cognitoUser.signOut()
                this.router.navigateByUrl('/')
              } else {
                (AWS.config.credentials as AWS.CognitoIdentityCredentials).params['Logins'][user_pool_key] = session_new.getIdToken().getJwtToken();
                (AWS.config.credentials as AWS.CognitoIdentityCredentials).refresh(err => {
                  if (err) {
                    cognitoUser.signOut()
                  } else {
                    // Token
                    // console.log('token refreshed')
                    const logged = this.sessionStorageService.getLoggedUser();
                    logged.token = session_new.getAccessToken().getJwtToken()
                    this.sessionStorageService.set("logged",logged)

                    this.httpClient.post<any>(`${environment.api_url}/api/user/update-token`,{
                      email:session_new.getIdToken().payload.email,
                      refresh_token:session_new.getRefreshToken().getToken(),
                      access_token:session_new.getAccessToken().getJwtToken(),
                    },this.getAuthEmailHeader(session_new.getIdToken().payload.email)).subscribe(res=>{
                      if(res.status==="ERROR"){
                        console.log(res.message)
                      }
                    });
                  }
                });
              }
            });
          }
        }else{

          // console.log('session error 3')
          // cognitoUser.signOut()
        }
      }
    })

  }

  getAuthHeader(){
    const logged = this.sessionStorageService.get('logged')
    let tok= ""
    if(logged!==undefined &&  logged.token !== undefined){
      tok  = logged.token
    }
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': tok
      })
    }
  }
  getAuthEmailHeader(email){
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': CryptoJS.AES.encrypt(email, environment.secret_key).toString()
      })
    }
  }

  encodeString(string){
    return CryptoJS.AES.encrypt(string, environment.secret_key).toString()
  }

  decodeString(string){
    const bytes  = CryptoJS.AES.decrypt(string,environment.secret_key);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  getEmailEncode(email){
    return CryptoJS.AES.encrypt(email, environment.secret_key).toString()
  }
  getAuthDemocratikHeader(){
    const logged = this.sessionStorageService.get('logged')
    const democratik_config = logged.integrations.find(x=>x.partner==="democratik")
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'AUTH': `Basic ${democratik_config.partner_config.api_key}`
      })
    }
  }
  // showNotify(type,message,title="",ops_config={}){
  //   let ops={}
  //   if(Object.keys(ops_config).length === 0){
  //     ops={
  //       // toastComponent:CallCenterToast,
  //       titleClass:'toast-title '+type,
  //       closeButton:true,
  //       timeOut:3000
  //       // easeTime:3000
  //     }
  //   }else{
  //     ops = {
  //       // toastComponent:CallCenterToast,
  //       titleClass:'toast-title '+type,
  //       ...ops_config
  //     }
  //   }
  //   // console.log(ops)
  //   if(type=="success"){
  //     if(title===""){
  //       title = "Success"
  //     }
  //     this.toastr.success(message,title,ops)
  //   }else if(type=="error"){
  //     if(title===""){
  //       title = "Error"
  //     }
  //     this.toastr.error(message,title,ops)
  //   }else if(type=="plivo"){
  //     if(title===""){
  //       title = "Alert Notification"
  //     }
  //     this.toastr.error(message,title,ops)
  //   }else if(type=="warning"){
  //     if(title===""){
  //       title = "Warning"
  //     }
  //     this.toastr.warning(message,title,ops)
  //   }else if(type=="info"){
  //     if(title===""){
  //       title = "Info"
  //     }
  //     this.toastr.info(message,title,ops)
  //   }
  // }
  showNotify(type,message,title="",ops_config={}){
    let ops={}
    if(Object.keys(ops_config).length === 0){
      ops={
        toastComponent:CallCenterToast,
        titleClass:'toast-title '+type,
        // timeOut:3000
        // easeTime:3000
      }
    }else{
      ops = {
        toastComponent:CallCenterToast,
        titleClass:'toast-title '+type,
        ...ops_config
      }
    }
    // console.log(ops)
    if(type=="success"){
      if(title===""){
        title = "Success"
      }
      this.toastr.success(message,title,ops)
    }else if(type=="error"){
      if(title===""){
        title = "Error"
      }
      this.toastr.error(message,title,ops)
    }else if(type=="plivo"){
      if(title===""){
        title = "Alert Notification"
      }
      this.toastr.error(message,title,ops)
    }else if(type=="warning"){
      if(title===""){
        title = "Warning"
      }
      this.toastr.warning(message,title,ops)
    }else if(type=="info"){
      if(title===""){
        title = "Info"
      }
      this.toastr.info(message,title,ops)
    }
  }
  addFormOverlay(is_modal=false){
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('form-open');
    if(is_modal===true){
      body.classList.add('form-open-modal');
    }
  }
  removeFormOverlay(){
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('form-open');
    body.classList.remove('form-open-modal');
  }
  checkValidPhone(phone){
    const phoneNumber = parsePhoneNumberFromString(phone, 'CA')
    if(phoneNumber && phoneNumber.isValid()===true){
      return phoneNumber.nationalNumber
    }
    return ""
  }
  checkValidEmail(email){
    return validator.isEmail(email)
  }
  isEmpty(obj) {
    for(var prop in obj) {
      if(obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }
  detectContactColumn(row){
    let index =0
    let column={}
    for(const col of row){
      switch (col.toLowerCase()) {
        case 'gender':
        case 'genre':
        case 'sexe':
          column['col_'+index]='gender'
          break;
        case 'name':
        case 'lastname':
        case 'last name':
        case 'last_name':
        case 'nom':
        case 'nom de famille':
          column['col_'+index]='last_name'
          break;
        case 'firstname':
        case 'first_name':
        case 'first name':
        case 'prénom':
        case 'prenom':
          column['col_'+index]='first_name'
          break;
        case 'address':
        case 'adresse 1':
        case 'address 1':
        case 'adresse':
          column['col_'+index]='address1'
          break;
        case 'postalcode':
        case 'zip':
        case 'codepostal':
        case 'code postal':
          column['col_'+index]='postal_code'
          break;

        case 'city':
        case 'ville':
        case 'municipalité':
        case 'municipalite':
        case 'commune':
          column['col_'+index]='city'
          break;
        case 'state':
        case 'province':
        case 'état':
        case 'etat':
          column['col_'+index]='state'
          break;
        case 'phone':
        case 'tel':
        case 'tél':
        case 'telephone':
        case 'téléphone':
        case 'phone1':
        case 'landline':
        case 'phone_number':
          column['col_'+index]='phone_number'
          break;

        case 'cell':
        case 'mobile':
        case 'téléphone mobile':
        case 'telephone mobile':
        case 'cellphone':
          column['col_'+index]='cell_phone'
          break;
        case 'email':
        case 'courriel':
          column['col_'+index]='email'
          break;
        case 'date of birth':
        case 'date_of_birth':
        case 'Naissance':
        case 'Anniversaire':
        case 'date de naissance':
        case 'DNS':
        case 'naissance':
          column['col_'+index]='date_of_birth'
          break;

        case 'tag':
        case 'tags':
        case 'étiquettes':
        case 'étiquette':
          column['col_'+index]='tag'
          break;
      }
      index++
    }
    return column
  }
  getCustomVariable(){
    return [
      {
        id:'first_name',
        text:this.translate.instant("First name")
      },
      {
        id:'last_name',
        text:this.translate.instant("Last name")
      },
      {
        id:'date_of_birth',
        text:this.translate.instant("Date of birth")
      },
      {
        id:'gender',
        text:this.translate.instant("Gender")
      },
      {
        id:'title',
        text:this.translate.instant("Title")
      },
      {
        id:'address1',
        text:this.translate.instant("Address1")
      },
      {
        id:'country',
        text:this.translate.instant("Country")
      },
      {
        id:'city',
        text:this.translate.instant("City")
      },
      {
        id:'state',
        text:this.translate.instant("State")
      },
      {
        id:'postal_code',
        text:this.translate.instant("Postal code")
      },
      {
        id:'phone_number',
        text:this.translate.instant("Phone number")
      },
      {
        id:'alt_phone',
        text:this.translate.instant("Alt phone")
      },
      {
        id:'cell_phone',
        text:this.translate.instant("Cell phone")
      },
      {
        id:'email',
        text:this.translate.instant("Email")
      }
    ]
  }
  getCustomVariableUser(){
    return [
      {
        id:'agent_first_name',
        text:this.translate.instant("First name")
      },
      {
        id:'agent_last_name',
        text:this.translate.instant("Last name")
      },
      {
        id:'agent_email',
        text:this.translate.instant("Email")
      }
    ]
  }

  dateObjectToMysqlDate(obj){
    let year = obj.year
    let month = (obj.month<10)?`0${obj.month}`:obj.month
    let day = (obj.day<10)?`0${obj.day}`:obj.day
    return `${year}-${month}-${day}`
  }

  mysqlDateToDateObject(obj){
    let date=obj.split("-")
    return {
      year:parseInt(date[0]),
      month:parseInt(date[1]),
      day:parseInt(date[2])
    }
  }

  replaceAll(str, find, replace) {
    let escapedFind=find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return str.replace(new RegExp(escapedFind, 'g'), replace);
  }

  replaceDialScript(script,contact){
    let customs = this.getCustomVariable()
    for(let custom of customs){
      let field = custom.id
      script = this.replaceAll(script,`[${field}]`,contact[field])
    }
    return script
  }

  getFixedStatusCode(){
    return ["NA","AA","AM","DROP","DNC","ADC","A","ER","BU","POINTED","VOTED","Cancelled"]
  }

  getContactImportColumn(){
    return [
      {
        label:this.translate.instant("First name"),
        description:this.translate.instant("First name"),
        name:'first_name'
      },
      {
        label:this.translate.instant("Last name"),
        description:this.translate.instant("Last name"),
        name:'last_name'
      },
      {
        label:this.translate.instant("Date of birth"),
        description:this.translate.instant("Date of birth"),
        name:'date_of_birth'
      },
      {
        label:this.translate.instant("Gender"),
        description:this.translate.instant("Gender"),
        name:'gender'
      },
      {
        label:this.translate.instant("Address")+" 1",
        description:this.translate.instant("Address")+" 1",
        name:'address1'
      },
      {
        label:this.translate.instant("Address")+" 2",
        description:this.translate.instant("Address")+" 2",
        name:'address2'
      },
      {
        label:this.translate.instant("Address")+" 3",
        description:this.translate.instant("Address")+" 3",
        name:'address3'
      },
      {
        label:this.translate.instant("City"),
        description:this.translate.instant("City"),
        name:'city'
      },
      {
        label:this.translate.instant("State"),
        description:this.translate.instant("State"),
        name:'state'
      },
      {
        label:this.translate.instant("Province"),
        description:this.translate.instant("Province"),
        name:'province'
      },
      {
        label:this.translate.instant("Postal code"),
        description:this.translate.instant("Postal code"),
        name:'postal_code'
      },
      {
        label:this.translate.instant("Phone number"),
        description:this.translate.instant("Phone number"),
        name:'phone_number'
      },
      {
        label:this.translate.instant("Alt phone"),
        description:this.translate.instant("Alt phone"),
        name:'alt_phone'
      },
      {
        label:this.translate.instant("Cell phone"),
        description:this.translate.instant("Cell phone"),
        name:'cell_phone'
      },
      {
        label:this.translate.instant("Email"),
        description:this.translate.instant("Email"),
        name:'email'
      },
      {
        label:this.translate.instant("Comment"),
        description:this.translate.instant("Comment"),
        name:'comment'
      },
      {
        label:this.translate.instant("Tag"),
        description:this.translate.instant("Tag"),
        name:'tag'
      }
    ]
  }
  showLoading(){
    let loading = document.querySelector('.loading-container')
    if(loading){
      (document.querySelector('.loading-container') as HTMLElement).style.display = 'block';
    }
  }
  hideLoading(){
    const loading = document.querySelector('.loading-container')
    if(loading){
      (document.querySelector('.loading-container') as HTMLElement).style.display = 'none';
    }
  }

  hexToRgb(hex){
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }
  applyOrganizationColor(){
    const logged = this.sessionStorageService.get('logged')

    if(logged.organization.color_primary=="" || logged.organization.color_primary==null){
      logged.organization.color_primary="#5E81F4"
    }

    if(logged.organization.color_secondary=="" || logged.organization.color_secondary==null){
      logged.organization.color_secondary="#81F45E"
    }

    if(logged.organization.color_tertiary=="" || logged.organization.color_tertiary==null){
      logged.organization.color_tertiary="#D15EF4"
    }

    const rgb_pri= this.hexToRgb(logged.organization.color_primary)
    const rgb_sec = this.hexToRgb(logged.organization.color_secondary)
    const rgb_ter= this.hexToRgb(logged.organization.color_tertiary)
    const colors = new Map([
      ['primary', logged.organization.color_primary],
      ['primary_opacity', `rgba(${rgb_pri.r},${rgb_pri.g},${rgb_pri.b},0.1)`],
      ['secondary', logged.organization.color_secondary],
      ['secondary_opacity', `rgba(${rgb_sec.r},${rgb_sec.g},${rgb_sec.b},0.1)`],
      ['tertiary', logged.organization.color_tertiary],
      ['tertiary_opacity', `rgba(${rgb_ter.r},${rgb_ter.g},${rgb_ter.b},0.1)`],
    ])
    
    Array.from(colors.entries()).forEach(([name, value]) => {
      document.body.style.setProperty(`--${name}`, value);
    })
  }
  getCurrentTimestamp(){
    return Math.round(new Date().getTime()/1000)    
  }
  getCampaignAction(){
    return [
      // {
      //   id:'send_email',
      //   name:this.translate.instant("Send email"),
      //   description:this.translate.instant("Send a predetermined email")
      // },
      // {
      //   id:'send_sms',
      //   name:this.translate.instant("Send sms"),
      //   description:this.translate.instant("Send a predetermined sms")
      // },
      {
        id:'open_an_url',
        name:this.translate.instant("Open an URL"),
        description:this.translate.instant("Open an URL")
      }
    ]
  }
}
