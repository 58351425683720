<div class="full-height" [ngClass]="{'show_config_bar':showConfig===true}" >
  
  <div class="config-bar  btn-warning"  *ngIf="showConfig===true"  >
    <a (click)="openConfigModal()" class="btn">{{"Config your organization"|translate}} ({{this.percent_completed}}% )</a>
    <a class="fa fa-times close-config" (click)="closeConfig()"></a>
  </div>

  <router-outlet></router-outlet>
  <div class="loading-container">
    <div class="loading-content">
      <i class="fa fa-shield-alt"></i>
    </div>
  </div>

  <ng-template #viewConfigModal let-modal>
    <app-config-organization (closeModal)="closeModal()" ></app-config-organization>
  </ng-template>

</div>
