import { NgSelect2Module } from 'ng-select2';
import { AuthGuardAdmin } from './core/services/auth-guard.admin.service';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from "@angular/common";


import { AuthGuard } from './core/services/auth-guard.service';

const routes: Routes = [
  {path:'',loadChildren:()=>import('./feature/feature.module').then(mod=>mod.FeatureModule)}
];

@NgModule({
  imports: [CommonModule,RouterModule.forRoot(routes),NgSelect2Module],
  exports: [RouterModule],
  providers: [AuthGuard,AuthGuardAdmin]
})
export class AppRoutingModule {}
