
  <div class="notify" [style.display]="state.value === 'inactive' ? 'none' : ''">
    <div class="notify-icon">
      <i *ngIf="options.titleClass=='toast-title success'" class="fa fa-check-circle"></i>
      <i *ngIf="options.titleClass=='toast-title error'" class="fa fa-ban"></i>
      <i *ngIf="options.titleClass=='toast-title warning'" class="fa fa-info-circle"></i>
      <i *ngIf="options.titleClass=='toast-title plivo'" class="fa fa-bell"></i>
    </div>
    <div class="notify-message">
      <div [class]="options.titleClass" [attr.aria-label]="title">
        {{ title }}
      </div>
      <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite"
           [class]="options.messageClass" [innerHTML]="message">
      </div>
      <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite"
           [class]="options.messageClass" [attr.aria-label]="message">
        {{ message }}
      </div>
      <a (click)="remove()" class="notify-close">
        <i class="fa fa-times"></i>
      </a>
    </div>
  </div>
  <div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
  </div>
  