import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  @Input() paging_id:string
  @Output() changePage = new EventEmitter<number>();
  @Output() changeLimit = new EventEmitter<string>();

  constructor() { }
  item_per_page=[]
  ngOnInit(): void {
    this.item_per_page=[
      {id:'10','text':10},
      {id:'10','text':30},
      {id:'10','text':60},
      {id:'10','text':120},
      {id:'10','text':240},
    ]
  }

}
