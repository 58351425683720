import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable()
export class ApiDemocratikService {
  api_name = "operation"
  constructor(
    private httpClient: HttpClient,
    private utils:UtilsService,
  ) {
  }
  getHeaders(token=""){
    return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'AUTH': `Basic ${token}`
        })
      }
  }
  
  connect(token):Observable<any> {
    return this.httpClient.get<any>(`${environment.democratik_api_url}/api/user`, this.getHeaders(token))
  }

  getOperation():Observable<any> {
    return this.httpClient.get<any>(`${environment.democratik_api_url}/api/${this.api_name}`, this.utils.getAuthDemocratikHeader())
  }

  getOperationInfo(id):Observable<any> {
    return this.httpClient.get<any>(`${environment.democratik_api_url}/api/${this.api_name}/${id}`, this.utils.getAuthDemocratikHeader())
  }

  

}



