import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable()
export class ApiPlivoService {
  api_name = "plivo"
  constructor(
    private httpClient: HttpClient,
    private utils:UtilsService
  ) {
  }
  verifyPhone(phone):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/verify-phone`,{phone}, this.utils.getAuthHeader())
  }
  verifyCode(phone,code):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/verify-code`,{phone,code}, this.utils.getAuthHeader())
  }

}



