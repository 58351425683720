import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject ,  Subject,Observable,of } from 'rxjs';
import {environment} from "../../../../environments/environment";
import {UtilsService} from "../utils.service";





@Injectable()
export class ApiSuperAdminService {
  api_name = "super-admin"
  constructor(private httpClient: HttpClient,private utils:UtilsService) {
  }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  getOrganizationList():Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/super-admin/organization`, this.utils.getAuthHeader());
  }
  runSQLQuery(organization_id,sql):Observable<any>{
    return this.httpClient.put<any>(`${environment.api_url}/api/super-admin/run-sql/${organization_id}`,{sql}, this.utils.getAuthHeader());
  }


  getTranslateJSON(lang="en"): Observable<any> {
    return this.httpClient.get(`./assets/i18n/${lang}.json`);
  }

  saveTranslateJSON(language="en",data):Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/super-admin/save-translate`,{language,data}, this.utils.getAuthHeader());
  }


  getOption(email):Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/super-admin/option`, this.utils.getAuthEmailHeader(email));
  }

}



