import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import * as CloudFront from 'aws-sdk/clients/cloudfront';
import {UtilsService} from "./utils.service";
import { environment } from './../../../environments/environment';
import * as moment from 'moment';
import {SessionStorageService} from "./session.storage.service";

import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

AWS.config.region = 'ca-central-1'; // e.g. us-east-1


@Injectable({
  providedIn: 'root'
})
export class AwsService {
  constructor(private utilsService:UtilsService,
              private sessionStorageService:SessionStorageService) {
  }
  fileUpload(file,cb,key_file="") {

    const login = JSON.parse(this.sessionStorageService.get('login_credential'))
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: environment.AwsIdentifyPoolId, // your identity pool id here
      Logins: login,
    });
    const bucket = new S3({
      apiVersion: '2006-03-01',
      region:'ca-central-1'
    });


    const contentType = file.type;
    const logged = this.sessionStorageService.getLoggedUser()
    const fileNameArr = file.name.split('.')
    const ext = fileNameArr[fileNameArr.length-1]
    const filename = this.utilsService.getRandomString(10)+"."+ext
    
    if(key_file===""){
      key_file=  logged.organization.id+'/'+filename
    }else{
      key_file = `${key_file}/${filename}`
    }
    const params = {
      Bucket: 'callcenterupload',
      Key: key_file,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };

    bucket.upload(params, (err: any, data: any) => {
        if (err) {
        console.log('EROOR: ',JSON.stringify( err));
        return false;
      }
      console.log('File Uploaded.', data);
      cb(data)
      return true;
    });
  }

  fileUploadCrop(file,cb) {

    const contentType = "image/png";
    
    const login = JSON.parse(this.sessionStorageService.get('login_credential'))
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: environment.AwsIdentifyPoolId, // your identity pool id here
      Logins: login,
    });
    const bucket = new S3({
      apiVersion: '2006-03-01',
      region:'ca-central-1'
    });


    const logged = this.sessionStorageService.getLoggedUser()

    const ext = "png"
    const filename = this.utilsService.getRandomString(10)+"."+ext

    const params = {
      Bucket: 'callcenterupload',
      Key: logged.organization.id+'/'+filename,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };
    bucket.upload(params, function (err, data) {
      if (err) {
        console.log('EROOR: ',JSON.stringify( err));
        return false;
      }
      console.log('File Uploaded.', data);
      cb(data)
      return true;
    });
  }
}
