<div class="row">
  <div class="col-sm-4">
    <div class="paging-size">
      <label>{{"items per page"|translate}}</label>:
      <ng-select2 [data]="item_per_page" class="select" [options]="{containerCssClass:'pagination_select',selectionCssClass:'pagination_select',dropdownCssClass:'pagination_select'}" #length name="limit" (change)="changeLimit.emit(length.value)"></ng-select2>
    </div>
  </div>
  <div class="col-sm-8">
    <pagination-template class="ngx-pagination" #p="paginationApi" id="{{paging_id}}"
                         (pageChange)="changePage.emit($event)">
      <ul>
        <li class="pagination-previous" [class.disabled]="p.isFirstPage()">
          <a *ngIf="!p.isFirstPage()" (click)="p.previous()"> <i class="fa fa-angle-left"></i> </a>
          <a *ngIf="p.isFirstPage()"> <i class="fa fa-angle-left"></i> </a>
        </li>
        <li *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
          <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
            <span>{{ page.label }}</span>
          </a>
          <div *ngIf="p.getCurrent() === page.value">
            <span>{{ page.label }}</span>
          </div>
        </li>
        <li class="pagination-next" [class.disabled]="p.isLastPage()">
          <a *ngIf="!p.isLastPage()" (click)="p.next()"> <i class="fa fa-angle-right"></i> </a>
          <a *ngIf="p.isLastPage()"> <i class="fa fa-angle-right"></i> </a>
        </li>
      </ul>
    </pagination-template>
  </div>
</div>
