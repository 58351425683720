import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable()
export class ApiGeneralService {
  api_name = "general"
  constructor(
    private httpClient: HttpClient,
    private utils:UtilsService
  ) {
  }

  checkConfig():Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/check-config`, this.utils.getAuthHeader())
  }
  sendEmail(obj):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/send-email`,obj, this.utils.getAuthHeader())
  }
  getConfigOption():Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/config-option`, this.utils.getAuthHeader())
  }
}